import { lazy } from "react";

const Dashboard = lazy(() =>
  import("../../views/Menu/Dashboard/Dashboard")
)

const ProjectWrapper = lazy(() =>
  import("../../views/Menu/Projects/ProjectWrapper")
)
const UsersWrapper = lazy(() =>
  import("../../views/Menu/Users/UsersWrapper")
)

const UsersAgreementWrapper = lazy(() =>
  import("../../views/Menu/UserAgreements/UsersAgreementWrapper")
)
const RatingWrapper = lazy(() =>
  import("../../views/Menu/Ratings/RatingWrapper")
)
// const Profile = lazy(() =>
//   import("../../views/Menu/Profile/Profile")
// )

export default (user) => menu();

const menu = () => [
  {
    url: "/dashboard",
    component: Dashboard,
  },
  {
    url: "/users",
    component: UsersWrapper,
  },
  {
    url: "/project",
    component: ProjectWrapper,
  },
  {
    url: "/user-agreement",
    component: UsersAgreementWrapper,
  },
  {
    url: "/review",
    component: RatingWrapper,
  },
  // {
  //   url: "/profile",
  //   component: Profile
  // },
]