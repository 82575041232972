import React, { Component, Suspense } from 'react';
import * as router from 'react-router-dom';
import {
    AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader,
    AppSidebarNav2 as AppSidebarNav,
    AppHeader
} from '@coreui/react';
import { translate } from 'react-switch-lang';
import DefaultHeader from './DefaultHeader';
import { Container, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import AuthRoute from './AuthRoute';
import Menu from './Menu';
import adminMenus from './admin-menu';

class SidebarNav extends AppSidebarNav {

    // nav type
    navType(item, idx) {
        return (
            item.title ? this.navTitle(item, idx)
                : item.divider ? this.navDivider(item, idx)
                    : item.label ? this.navLabel(item, idx)
                        : item.children ? this.navDropdown(item, idx)
                            : typeof item.render === 'function' ? item.render(item, idx)
                                : this.navItem(item, idx)
        );
    }
}
class DefaultLayout extends Component {
    generateRoutes = () => {
        return Menu(this.props.user).map((props, idx) => (
            <AuthRoute
                key={idx}
                path={props.url}
                exact={!!props.exact}
                component={props.component}
                {...props}
            />
        ));
    }

    generateMenus(menu) {
        return adminMenus(this.props.user)
            .filter(routeObj => !!routeObj.menu)
            .map(({ menu, url }) => {
                return { ...menu, url }
            });
    }

    render() {
        const { Switch, Redirect } = router;
        return (
            <div className="app">
                <AppHeader fixed><DefaultHeader /></AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader />
                        <AppSidebarForm />
                        <Suspense>
                            <SidebarNav navConfig={{ items: this.generateMenus() }} router={router} location={this.props.location} />
                        </Suspense>
                        <AppSidebarFooter />
                    </AppSidebar>
                    <main className="main">
                        <Container className="mt-5">
                            <Suspense
                                fallback={<div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        background: "rgba(255,255,255, 0.5)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Spinner style={{ width: 48, height: 48 }} />
                                </div>}
                            >
                                <Switch>
                                    <Redirect exact from="/home" to="/dashboard" />
                                    {this.generateRoutes()}
                                </Switch>
                            </Suspense>
                        </Container>
                    </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (reduxState) => ({ user: reduxState.user })

export default connect(mapStateToProps)(translate(DefaultLayout));
