import React from 'react';

export function lazyComponent(fn) {
    const promise = (loads) => new Promise((resolve, reject) => {
        loads().then(resolve)
            .catch((err) => {
                import('./SomethingWrongView').then(resolve)
            })
    })

    return React.lazy(() => promise(fn));
}
