import { subscribe } from "react-switch-lang";
import { lazyComponent as lazy } from '../../components/lazyComponent';

var cachedRoutes = {};
subscribe(() => {
  cachedRoutes = {};
})

// route object
// {url, component, privileges, menu: { name, icon }}
export default (user, props) => {
  if (cachedRoutes[user.session_id]) {
    return cachedRoutes[user.session_id];
  }

  const routes = [];

  routes.push({
    url: "/dashboard",
    component: lazy(() => import("../../views/Menu/Dashboard/Dashboard")),
    menu: {
      name: "Dashboard",
      icon: "icon-home",
    },
  });

  routes.push({
    url: "/users",
    component: lazy(() => import("../../views/Menu/Users/UsersWrapper")),
    menu: {
      name: "Users",
      icon: "icon-user",
    },
  });

  routes.push({
    url: "/project",
    component: lazy(() => import("../../views/Menu/Projects/ProjectWrapper")),
    menu: {
      name: "Projects",
      icon: "fa fa-money",
    },
  });

  routes.push({
    url: "/user-agreement",
    component: lazy(() => import("../../views/Menu/UserAgreements/UsersAgreementWrapper")),
    menu: {
      name: "User Agreements",
      icon: "fa fa-handshake-o",
    },
  });

  routes.push({
    url: "/review",
    component: lazy(() => import("../../views/Menu/Ratings/RatingWrapper")),
    menu: {
      name: "Reviews",
      icon: "fa fa-star",
    },
  });

  cachedRoutes[user.session_id] = routes;
  return routes;
}
