export const color = {
    a : "#f2c85b",
    b : "#fba465",
    c : "#ee3e38",
    d : "#53ccec",
    e : "#1974d3",
    f : "#000181",
    g : "#9db300",
    h : "#fccf14",
    i : "#ffe169",
    j : "#1d1e4e",
    k : "#a155b9",
    l : "#ff9001",
    m : "#ff4200",
    n : "#5e2390",
    o : "#6b0d88",
    p : "#ffb900",
    q : "#f5ac4e",
    r : "#940700",
    s : "#fc9e5b",
    t : "#41c8e5",
    u : "#9a75fc",
    v : "#bf3612",
    w : "#f24f09",
    x : "#f2c070",
    y : "#5073d9",
    z : "#044855"
}