import React, { useRef } from 'react';
import { LandingPageProvider } from './context';
import { translate } from "react-switch-lang";
import PageLayout from './PageLayout';
import Login from '../Auth/Login/Login';

function LandingPage() {

    const homeRef = useRef();
    const aboutRef = useRef();
    const contactRef = useRef();

    return (
        <LandingPageProvider value={{ homeRef, aboutRef, contactRef }}>
            <PageLayout>
                <Login />
            </PageLayout>
        </LandingPageProvider>
    )
}

export default translate(LandingPage);
